export const shapesCountries = [
    {
        category: 'Countries',
        key: 'argentina',
        name: 'Argentina',
        path: "/shapes/argentina.png"
    },
    {
        category: 'Countries',
        key: 'australia',
        name: 'Australia',
        path: "/shapes/australia.png"
    },
    {
        category: 'Countries',
        key: 'belarus',
        name: 'Belarus',
        path: "/shapes/belarus.png"
    },
    {
        category: 'Countries',
        key: 'belgium',
        name: 'Belgium',
        path: "/shapes/belgium.png"
    },
    {
        category: 'Countries',
        key: 'bolivia',
        name: 'Bolivia',
        path: "/shapes/bolivia.png"
    },
    {
        category: 'Countries',
        key: 'brazil',
        name: 'Brazil',
        path: "/shapes/brazil.png"
    },
    {
        category: 'Countries',
        key: 'bulgaria',
        name: 'Bulgaria',
        path: "/shapes/bulgaria.png"
    },
    {
        category: 'Countries',
        key: 'canada',
        name: 'Canada',
        path: "/shapes/canada.png"
    },
    {
        category: 'Countries',
        key: 'china',
        name: 'China',
        path: "/shapes/china.png"
    },
    {
        category: 'Countries',
        key: 'colombia',
        name: 'Colombia',
        path: "/shapes/colombia.png"
    },
    {
        category: 'Countries',
        key: 'costa_rica',
        name: 'Costa Rica',
        path: "/shapes/costa_rica.png"
    },
    {
        category: 'Countries',
        key: 'cyprus',
        name: 'Cyprus',
        path: "/shapes/cyprus.png"
    },
    {
        category: 'Countries',
        key: 'czech_republic',
        name: 'Czech Republic',
        path: "/shapes/czech_republic.png"
    },
    {
        category: 'Countries',
        key: 'denmark',
        name: 'Denmark',
        path: "/shapes/denmark.png"
    },
    {
        category: 'Countries',
        key: 'estonia',
        name: 'Estonia',
        path: "/shapes/estonia.png"
    },
    {
        category: 'Countries',
        key: 'finland',
        name: 'Finland',
        path: "/shapes/finland.png"
    },
    {
        category: 'Countries',
        key: 'france',
        name: 'France',
        path: "/shapes/france.png"
    },
    {
        category: 'Countries',
        key: 'germany',
        name: 'Germany',
        path: "/shapes/germany.png"
    },
    {
        category: 'Countries',
        key: 'greece',
        name: 'Greece',
        path: "/shapes/greece.png"
    },
    {
        category: 'Countries',
        key: 'honduras',
        name: 'Honduras',
        path: "/shapes/honduras.png"
    },
    {
        category: 'Countries',
        key: 'hungary',
        name: 'Hungary',
        path: "/shapes/hungary.png"
    },
    {
        category: 'Countries',
        key: 'iceland',
        name: 'Iceland',
        path: "/shapes/iceland.png"
    },
    {
        category: 'Countries',
        key: 'india',
        name: 'India',
        path: "/shapes/india.png"
    },
    {
        category: 'Countries',
        key: 'ireland',
        name: 'Ireland',
        path: "/shapes/ireland.png"
    },
    {
        category: 'Countries',
        key: 'italy',
        name: 'Italy',
        path: "/shapes/italy.png"
    },
    {
        category: 'Countries',
        key: 'latvia',
        name: 'Latvia',
        path: "/shapes/latvia.png"
    },
    {
        category: 'Countries',
        key: 'lithuania',
        name: 'Lithuania',
        path: "/shapes/lithuania.png"
    },
    {
        category: 'Countries',
        key: 'malta',
        name: 'Malta',
        path: "/shapes/malta.png"
    },
    {
        category: 'Countries',
        key: 'mexico',
        name: 'Mexico',
        path: "/shapes/mexico.png"
    },
    {
        category: 'Countries',
        key: 'moldova',
        name: 'Moldova',
        path: "/shapes/moldova.png"
    },
    {
        category: 'Countries',
        key: 'new_zealand',
        name: 'New Zealand',
        path: "/shapes/new_zealand.png"
    },
    {
        category: 'Countries',
        key: 'netherlands',
        name: 'Netherlands',
        path: "/shapes/netherlands.png"
    },
    {
        category: 'Countries',
        key: 'norway',
        name: 'Norway',
        path: "/shapes/norway.png"
    },
    {
        category: 'Countries',
        key: 'peru',
        name: 'Peru',
        path: "/shapes/peru.png"
    },
    {
        category: 'Countries',
        key: 'poland',
        name: 'Poland',
        path: "/shapes/poland.png"
    },
    {
        category: 'Countries',
        key: 'portugal',
        name: 'Portugal',
        path: "/shapes/portugal.png"
    },
    {
        category: 'Countries',
        key: 'romania',
        name: 'Romania',
        path: "/shapes/romania.png"
    },
    {
        category: 'Countries',
        key: 'russia',
        name: 'Russia',
        path: "/shapes/russia.png"
    },
    {
        category: 'Countries',
        key: 'slovakia',
        name: 'Slovakia',
        path: "/shapes/slovakia.png"
    },
    {
        category: 'Countries',
        key: 'slovenia',
        name: 'Slovenia',
        path: "/shapes/slovenia.png"
    },
    {
        category: 'Countries',
        key: 'south_africa',
        name: 'South Africa',
        path: "/shapes/south_africa.png"
    },
    {
        category: 'Countries',
        key: 'south_korea',
        name: 'South Korea',
        path: "/shapes/south_korea.png"
    },
    {
        category: 'Countries',
        key: 'spain',
        name: 'Spain',
        path: "/shapes/spain.png"
    },
    {
        category: 'Countries',
        key: 'sweden',
        name: 'Sweden',
        path: "/shapes/sweden.png"
    },
    {
        category: 'Countries',
        key: 'switzerland',
        name: 'Switzerland',
        path: "/shapes/switzerland.png"
    },
    {
        category: 'Countries',
        key: 'thailand',
        name: 'Thailand',
        path: "/shapes/thailand.png"
    },
    {
        category: 'Countries',
        key: 'turkey',
        name: 'Turkey',
        path: "/shapes/turkey.png"
    },
    {
        category: 'Countries',
        key: 'ukraine',
        name: 'Ukraine',
        path: "/shapes/ukraine.png"
    },
    {
        category: 'Countries',
        key: 'united_kingdom',
        name: 'United Kingdom',
        path: "/shapes/united_kingdom.png"
    },
    {
        category: 'Countries',
        key: 'uruguay',
        name: 'Uruguay',
        path: "/shapes/uruguay.png"
    },
    {
        category: 'Countries',
        key: 'USA1',
        name: 'USA',
        description: 'with Alaska and Hawaii',
        path: "/shapes/usa_alaska.png"
    },
    {
        category: 'Countries',
        key: 'USA2',
        name: 'USA',
        description: 'without Alaska and Hawaii',
        path: "/shapes/usa_noAlaska.png"
    },
    {
        category: 'Countries',
        key: 'venezuela',
        name: 'Venezuela',
        path: "/shapes/venezuela.png"
    }
];