export const shapesStates = [
    {
        category: 'USA States',
        key: 'WA_State',
        name: 'Washington',
        path: "/shapes/state_washington.png"
    },
    {
        category: 'USA States',
        key: 'KS_State',
        name: 'Kansas',
        path: "/shapes/state_kansas.png"
    },
    {
        category: 'USA States',
        key: 'AZ_State',
        name: 'Arizona',
        path: "/shapes/state_arizona.png"
    },
    {
        category: 'USA States',
        key: 'CA_State',
        name: 'California',
        path: "/shapes/state_california.png"
    },
    {
        category: 'USA States',
        key: 'CO_State',
        name: 'Colorado',
        path: "/shapes/state_colorado.png"
    },
    {
        category: 'USA States',
        key: 'ID_State',
        name: 'Idaho',
        path: "/shapes/state_idaho.png"
    },
    {
        category: 'USA States',
        key: 'MT_State',
        name: 'Montana',
        path: "/shapes/state_montana.png"
    },
    {
        category: 'USA States',
        key: 'NE_State',
        name: 'Nebraska',
        path: "/shapes/state_nebraska.png"
    },
    {
        category: 'USA States',
        key: 'NV_State',
        name: 'Nevada',
        path: "/shapes/state_nevada.png"
    },
    {
        category: 'USA States',
        key: 'NM_State',
        name: 'New Mexico',
        path: "/shapes/state_new_mexico.png"
    },
    {
        category: 'USA States',
        key: 'ND_State',
        name: 'North Dakota',
        path: "/shapes/state_north_dakota.png"
    },
    {
        category: 'USA States',
        key: 'SD_State',
        name: 'South Dakota',
        path: "/shapes/state_south_dakota.png"
    },
    {
        category: 'USA States',
        key: 'OK_State',
        name: 'Oklahoma',
        path: "/shapes/state_oklahoma.png"
    },
    {
        category: 'USA States',
        key: 'OR_State',
        name: 'Oregon',
        path: "/shapes/state_oregon.png"
    },
    {
        category: 'USA States',
        key: 'TX_State',
        name: 'Texas',
        path: "/shapes/state_texas.png"
    },
    {
        category: 'USA States',
        key: 'UT_State',
        name: 'Utah',
        path: "/shapes/state_utah.png"
    },
    {
        category: 'USA States',
        key: 'WY_State',
        name: 'Wyoming',
        path: "/shapes/state_wyoming.png"
    },
    {
        category: 'USA States',
        key: 'AL_State',
        name: 'Alabama',
        path: "/shapes/state_alabama.png"
    },
    {
        category: 'USA States',
        key: 'AK_State',
        name: 'Alaska',
        path: "/shapes/state_alaska.png"
    },
    {
        category: 'USA States',
        key: 'AR_State',
        name: 'Arkansas',
        path: "/shapes/state_arkansas.png"
    },
    {
        category: 'USA States',
        key: 'FL_State',
        name: 'Florida',
        path: "/shapes/state_florida.png"
    },
    {
        category: 'USA States',
        key: 'GA_State',
        name: 'Georgia',
        path: "/shapes/state_georgia.png"
    },
    {
        category: 'USA States',
        key: 'HI_State',
        name: 'Hawaii',
        path: "/shapes/state_hawaii.png"
    },
    {
        category: 'USA States',
        key: 'LA_State',
        name: 'Louisiana',
        path: "/shapes/state_louisiana.png"
    },
    {
        category: 'USA States',
        key: 'MS_State',
        name: 'Mississippi',
        path: "/shapes/state_mississippi.png"
    },
    {
        category: 'USA States',
        key: 'NC_State',
        name: 'North Carolina',
        path: "/shapes/state_north_carolina.png"
    },
    {
        category: 'USA States',
        key: 'SC_State',
        name: 'South Carolina',
        path: "/shapes/state_south_carolina.png"
    },
    {
        category: 'USA States',
        key: 'TN_State',
        name: 'Tennessee',
        path: "/shapes/state_tennessee.png"
    },
    {
        category: 'USA States',
        key: 'NH_State',
        name: 'New Hampshire',
        path: "/shapes/state_new_hampshire.png"
    },
    {
        category: 'USA States',
        key: 'CT_State',
        name: 'Connecticut',
        path: "/shapes/state_connecticut.png"
    },
    {
        category: 'USA States',
        key: 'DE_State',
        name: 'Delaware',
        path: "/shapes/state_delaware.png"
    },
    {
        category: 'USA States',
        key: 'IL_State',
        name: 'Illinois',
        path: "/shapes/state_illinois.png"
    },
    {
        category: 'USA States',
        key: 'IN_State',
        name: 'Indiana',
        path: "/shapes/state_indiana.png"
    },
    {
        category: 'USA States',
        key: 'IA_State',
        name: 'Iowa',
        path: "/shapes/state_iowa.png"
    },
    {
        category: 'USA States',
        key: 'KY_State',
        name: 'Kentucky',
        path: "/shapes/state_kentucky.png"
    },
    {
        category: 'USA States',
        key: 'ME_State',
        name: 'Main',
        path: "/shapes/state_main.png"
    },
    {
        category: 'USA States',
        key: 'MA_State',
        name: 'Massachusetts',
        path: "/shapes/state_massachusetts.png"
    },
    {
        category: 'USA States',
        key: 'MI_State',
        name: 'Michigan',
        path: "/shapes/state_michigan.png"
    },
    {
        category: 'USA States',
        key: 'MN_State',
        name: 'Minnesota',
        path: "/shapes/state_minnesota.png"
    },
    {
        category: 'USA States',
        key: 'MS_State',
        name: 'Missouri',
        path: "/shapes/state_missouri.png"
    },
    {
        category: 'USA States',
        key: 'NJ_State',
        name: 'New Jersey',
        path: "/shapes/state_new_jersey.png"
    },
    {
        category: 'USA States',
        key: 'NY_State',
        name: 'New York',
        path: "/shapes/state_new_york.png"
    },
    {
        category: 'USA States',
        key: 'OH_State',
        name: 'Ohio',
        path: "/shapes/state_ohio.png"
    },
    {
        category: 'USA States',
        key: 'PA_State',
        name: 'Pennsylvania',
        path: "/shapes/state_pennsylvania.png"
    },
    {
        category: 'USA States',
        key: 'RI_State',
        name: 'Rhode Island',
        path: "/shapes/state_rhode_island.png"
    },
    {
        category: 'USA States',
        key: 'VT_State',
        name: 'Vermont',
        path: "/shapes/state_vermont.png"
    },
    {
        category: 'USA States',
        key: 'VA_State',
        name: 'Virginia',
        path: "/shapes/state_virginia.png"
    },
    {
        category: 'USA States',
        key: 'WV_State',
        name: 'West Virginia',
        path: "/shapes/state_west_virginia.png"
    },
    {
        category: 'USA States',
        key: 'WI_State',
        name: 'Wisconsin',
        path: "/shapes/state_wisconsin.png"
    }
];