import {ajaxCallWrapper} from "./ajaxStatusHelper";
import {toastr} from 'react-redux-toastr'
import shapen from '../shapeners/basicShapener'
import {getZazzleMugLink} from "../temporaryStorage/getTemporaryLink";

export function shape(file, shapeFile, canvas, opts, callback) {
    return function (dispatch) {
        return ajaxCallWrapper(
            dispatch,
            new Promise(async function (resolve, reject) {
                try {
                    setTimeout(async () => {
                        try {
                            const fileData = await shapen(file, shapeFile, canvas, opts);

                            resolve(fileData);
                        } catch (e) {
                            reject(e);
                        }
                    }, 0);
                } catch (e) {
                    reject(e);
                }
            }).then(fileData => {
                callback(fileData);
            }).catch(error => {
                toastr.error(error.message || "Unexpected error");
            }));
    }
}

export function makeACup(url, shape, newWindow) {
    return function (dispatch) {
        let link = getZazzleMugLink(url, shape.name);
        window.open(link, "_blank");
    }
}