import React, {Component} from 'react';
import Main from "./components/Main";
import {Provider} from "react-redux";
import ReduxToastr from "react-redux-toastr";

import './App.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'semantic-ui-css/semantic.min.css';
import configureStore from "./store/configureStore";

const store = configureStore();

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <div>
                    <Main/>
                    <ReduxToastr
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"/>
                </div>
            </Provider>
        );
    }
}

export default App;
