import getPixelsAsync from "./getPixelsAsync";

function getWidth(ndArray) {
    return ndArray.shape[0];
}

function getHeight(ndArray) {
    return ndArray.shape[1];
}

function getCorrespondingCoordinates(x, y, shape, width, height) {
    let correspondingShapePointX = Math.round(getWidth(shape) * x / width);
    let correspondingShapePointY = Math.round(getHeight(shape) * y / height);

    return {
        x: correspondingShapePointX,
        y: correspondingShapePointY
    }
}

function fillWithColor(data, x, y, width, color) {
    data[y * 4 * width + x * 4 + 0] = color.r;
    data[y * 4 * width + x * 4 + 1] = color.g;
    data[y * 4 * width + x * 4 + 2] = color.b;
    data[y * 4 * width + x * 4 + 3] = color.a * 100;
}

function getDeltas(borderWidth, angles) {
    return angles.map(a => {
        return {
            deltaX: Math.round(borderWidth * Math.cos(a)),
            deltaY: Math.round(borderWidth * Math.sin(a))
        }
    });
}

export default async function shapen(imageUri, shapeUri, canvas, options) {
    try {
        const image = await getPixelsAsync(imageUri);
        const shape = await getPixelsAsync(shapeUri);

        let width, height, startX, startY;
        let sourceRatio = getHeight(image) / getWidth(image);
        let shapeRatio = getHeight(shape) / getWidth(shape);

        if (sourceRatio > shapeRatio) {
            height = Math.round(getWidth(image) * shapeRatio);
            width = getWidth(image);
            startX = 0;
            startY = Math.round((getHeight(image) - height) / 2);
        } else {
            height = getHeight(image);
            width = Math.round(getHeight(image) / shapeRatio);
            startX = Math.round((getWidth(image) - width) / 2);
            startY = 0;
        }

        //let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        let context = canvas.getContext("2d");

        //context.clearRect(0, 0, canvas.width, canvas.height);
        let imageData=context.createImageData(width, height);
        let data=imageData.data;

        for (let x = 0; x <= width - 1; x++) {
            for (let y = 0; y <= height - 1; y++) {
                const correspondingShapePoint = getCorrespondingCoordinates(x, y, shape, width, height);
                const transparency = shape.get(correspondingShapePoint.x, correspondingShapePoint.y, 3);
                if (!transparency) {
                    data[y * 4 * width + x * 4 + 0] = 255;
                    data[y * 4 * width + x * 4 + 1] = 255;
                    data[y * 4 * width + x * 4 + 2] = 255;
                    data[y * 4 * width + x * 4 + 3] = 255;
                    continue;
                }

                data[y * 4 * width + x * 4 + 0] = image.get(x + startX, y + startY, 0);
                data[y * 4 * width + x * 4 + 1] = image.get(x + startX, y + startY, 1);
                data[y * 4 * width + x * 4 + 2] = image.get(x + startX, y + startY, 2);
                data[y * 4 * width + x * 4 + 3] = 255;
            }
        }

        if (options && options.border && options.border.width) {
            const borderWidth = options.border.width;
            const borderColor = options.border.color || { r: 0, g: 0, b: 0, a: 255 };

            let deltas = [];

            if (borderWidth <= 3) {
                deltas = getDeltas(borderWidth, [0, 45, 90, 135, 180, 225, 270, 315]);
            } else {
                deltas = getDeltas(borderWidth, [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330]);
            }

            for (let x = 1; x <= width - 1; x++) {
                for (let y = 1; y <= height - 1; y++) {
                    const correspondingShapePoint = getCorrespondingCoordinates(x, y, shape, width, height)
                    if (shape.get(correspondingShapePoint.x, correspondingShapePoint.y, 3) !== 0) {
                        continue;
                    }

                    for (var delta of deltas) {
                        const xx = x + delta.deltaX;
                        const yy = y + delta.deltaY;
                        if (xx > 0 && yy > 0 && xx < width && yy < height) {
                            const correspondingShapePoint = getCorrespondingCoordinates(
                                xx,
                                yy,
                                shape,
                                width,
                                height);
                            if (shape.get(correspondingShapePoint.x, correspondingShapePoint.y, 3) !== 0) {
                                fillWithColor(data, x, y, width, borderColor);
                                break;
                            }

                        }
                    }
                }
            }
        }

        context.putImageData(imageData, 0, 0);

        return {
            data: canvas.toDataURL("image/jpeg"),
            canvas,
            width,
            height
        };
    }
    catch (e) {
        console.error("ERROR:" + JSON.stringify(e));

        throw e;
    }
}