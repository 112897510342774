import firebase from 'firebase';

let firebaseConfig = {
    apiKey: "AIzaSyC-w3UQ2nIU8ZupD6-EFR6H_OceZBHRI0A",
    authDomain: "geo-shaped.firebaseapp.com",
    databaseURL: "https://geo-shaped.firebaseio.com",
    projectId: "geo-shaped",
    storageBucket: "geo-shaped.appspot.com",
    messagingSenderId: "511617278975",
    appId: "1:511617278975:web:8dec3736355a7725"
};

firebase.initializeApp(firebaseConfig);
firebase.auth().signInWithEmailAndPassword('geo-shaped@geo-shaped.com', 'geo-shaped');
export default firebase;