import getPixels from 'get-pixels';

export default async function getPixelsAsync(imageUri) {
    return new Promise(function (resolve, reject) {
        getPixels(imageUri, (err, pixels) => {
            if (err) {
                reject(err);
            }

            resolve(pixels);
        });
    });
}