import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Modal, Dimmer, Grid, Loader, Button, Dropdown, Image as SemanticImage} from 'semantic-ui-react'
import ImageUpload from "./ImageUpload";
import {bindActionCreators} from "redux";
import * as shapeActions from "../actions/shapeActions"
import {shapesStates} from "../shapesStates"
import {shapesCountries} from "../shapesCountries"
import {categories} from "../categories"
import {SketchPicker} from 'react-color';
import {makeACup} from "../actions/shapeActions";
import photoImg from "../images/photo.jpg";
import shapeImg from "../images/shape.png";
import shapenedImg from "../images/shapened.jpeg";
import mugImg from "../images/mug.jpg";
import firebase from "../firebaseApp";

class Main extends React.Component {
    state = {
        selectedState: undefined,
        selectedShape: undefined,
        selectedImageUri: undefined,
        shapedImageUri: undefined,
        selectedBorder: 0,
        displayColorPicker: false,
        loading: false,
        progress: 0,
        borderColor: {
            r: '0',
            g: '0',
            b: '0',
            a: '255',
        }
    };

    onImageSelected = (image) => {
        this.setState({selectedImageUri: image.uri});

    };

    dataURLtoBlob = (dataurl) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type: mime});
    };

    saveToFile = () => {
        const dataUrl = this.refs.canvas.toDataURL("image/jpeg");
        const blob = this.dataURLtoBlob(dataUrl);
        this.refs.link.href = URL.createObjectURL(blob);
        this.refs.link.download = "my-shapened-photo.jpeg";
        this.refs.link.click();
    };

    makeACup = () => {
        let _this = this;
        let uniqueId = Math.random().toString(36).substring(2) + Date.now().toString(36) + '.jpeg';
        let storageRef = firebase.storage().ref();

        this.refs.canvas.toBlob(function (blob) {
            let image = new Image();
            image.src = blob;
            console.warn(Date());
            let uploadTask = storageRef.child('images/' + uniqueId).put(blob);

            _this.setState({loading: true, progress: 0});
            uploadTask.on('state_changed', function (snapshot) {
                let progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                //console.log('Upload is ' + progress + '% done');
                _this.setState({loading: true, progress});

            }, function (error) {
                // Handle unsuccessful uploads
            }, function () {
                _this.setState({loading: false, progress: 0});
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    console.warn(Date());
                    _this.setState({shapedImageUri: url});
                });
            });
        });
    };

    onCategoryChange = (e, {value}) => {
        this.setState({selectedCategory: value});
    };

    onShapeChange = (e, {value}) => {
        this.setState({selectedShape: value});
    };

    onBorderChange = (e, {value}) => {
        this.setState({selectedBorder: value});
    };

    handleColorPickerClick = () => {
        this.setState({displayColorPicker: !this.state.displayColorPicker})
    };

    handleColorPickerClose = () => {
        this.setState({displayColorPicker: false})
    };

    handleColorPickerChange = (color) => {
        this.setState({borderColor: color.rgb});
    };

    shapePhoto = () => {
        const path = [...shapesStates, ...shapesCountries].find(sh => sh.key === this.state.selectedShape).path;
        this.setState({shapedPhoto: undefined});

        this.props.shapeActions.shape(
            this.state.selectedImageUri,
            path,
            this.refs.canvas,
            {border: {width: this.state.selectedBorder, color: this.state.borderColor}},
            fileData => this.setState({shapedPhoto: fileData}));
    };

    handleGoToCupClick = () => {
        const shape = [...shapesStates, ...shapesCountries].find(sh => sh.key === this.state.selectedShape);

        this.props.makeACup(this.state.shapedImageUri, shape);
        this.setState({shapedImageUri: undefined});

    };

    render() {
        const categoryOptions = categories.map(c => {
            return {text: c, value: c}
        });

        const shapeOptions = [...shapesStates, ...shapesCountries]
            .sort((sh1, sh2) => sh1.name > sh2.name ? 1 : -1)
            .filter(sh => sh.category === this.state.selectedCategory)
            .map(sh => {
                return {
                    text: sh.name,
                    value: sh.key,
                    content: sh.name + (sh.description ? ` (${sh.description})` : '')
                }
            });

        const borderSizeOptions = [
            {
                text: `No border`,
                value: 0
            },
            {
                text: `2 px`,
                value: 2
            },
            {
                text: `3 px`,
                value: 3
            },
            {
                text: `5 px`,
                value: 5
            },
            {
                text: `10 px`,
                value: 10
            }];

        const colorPickerStyles = {
            color: {
                width: '36px',
                height: '24px',
                borderRadius: '2px',
                background: `rgba(${this.state.borderColor.r}, ${this.state.borderColor.g}, ${this.state.borderColor.b}, ${this.state.borderColor.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            }
        };

        return (
            <div>
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <div style={{margin: '5px', textAlign: 'center'}}>
                                <h3>1. Select a photo</h3>
                                <SemanticImage src={photoImg}/>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <div style={{margin: '5px', textAlign: 'center'}}>
                                <h3>2. Select a shape</h3>
                                <SemanticImage src={shapeImg}/>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <div style={{margin: '5px', textAlign: 'center'}}>
                                <h3>3. Shape</h3>
                                <SemanticImage src={shapenedImg}/>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <div style={{margin: '5px', textAlign: 'center'}}>
                                <h3>4. Order a cup</h3>
                                <SemanticImage src={mugImg}/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingBottom: 0}}>
                        <Grid.Column width={8}>
                            <ImageUpload
                                onSelected={this.onImageSelected}
                                fileId={this.props.photoId}
                            />
                            <Dropdown
                                selection
                                style={{width: '150px', margin: '5px'}}
                                placeholder='Select Category'
                                options={categoryOptions}
                                onChange={this.onCategoryChange}
                            />
                            <Dropdown
                                selection
                                style={{width: '150px', margin: '5px'}}
                                placeholder='Select Shape'
                                options={shapeOptions}
                                onChange={this.onShapeChange}
                            />
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Button
                                primary
                                style={{margin: '5px', width: '150px'}}
                                disabled={!this.state.selectedImageUri || !this.state.selectedShape}
                                onClick={this.shapePhoto}
                            >
                                Shape
                            </Button>
                            <Button id='save-to-file-button'
                                    secondary
                                    style={{margin: '5px', width: '150px'}}
                                    disabled={!this.state.shapedPhoto}
                                    onClick={this.saveToFile}
                            >

                                Save to file
                            </Button>
                            <Button id='share-to-facebook'
                                    secondary
                                    style={{margin: '5px', width: '150px'}}
                                    disabled={!this.state.shapedPhoto}
                                    onClick={this.makeACup}
                            >

                                Order a cup
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: 0}}>
                        <Grid.Column width={16}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>
                                    <h3 style={{margin: '5px'}}>Border:</h3>
                                </div>
                                <Dropdown
                                    selection
                                    style={{width: '150px', margin: '5px'}}
                                    placeholder='Select Border'
                                    value={this.state.selectedBorder}
                                    options={borderSizeOptions}
                                    onChange={this.onBorderChange}
                                />
                                <div style={{margin: '5px', marginTop: '8px'}}>
                                    <div style={colorPickerStyles.swatch} onClick={this.handleColorPickerClick}>
                                        <div style={colorPickerStyles.color}/>
                                    </div>
                                    {this.state.displayColorPicker ? <div style={colorPickerStyles.popover}>
                                        <div style={colorPickerStyles.cover} onClick={this.handleColorPickerClose}/>
                                        <SketchPicker color={this.state.borderColor}
                                                      onChange={this.handleColorPickerChange}/>
                                    </div> : null}
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <div style={{scroll: 'auto', width: "0px", height: "0px", overflow: "hidden"}}>
                                <canvas ref="canvas"/>
                                <a ref="link"/>
                            </div>
                            {this.state.shapedPhoto ?
                                <div style={{width: "100%", position: 'relative'}}>
                                    <img src={this.state.shapedPhoto.data}
                                         alt='mapshaped'
                                         style={{
                                             width: "100%",
                                             height: 'auto',
                                             margin: '0 auto',
                                             display: 'block',
                                             maxHeight: '800px',
                                             overflow: 'hidden',
                                             objectFit: 'scale-down'
                                         }}
                                    />
                                </div>
                                :
                                <div style={{
                                    position: "relative",
                                    float: "left",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }}>
                                    <h2>
                                        Image not shaped
                                    </h2>
                                </div>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Dimmer active={this.props.loading || this.state.loading} page>
                    <Loader>Please wait{this.state.progress ? ` ${this.state.progress}%` : '...'}</Loader>
                </Dimmer>
                <Modal
                    open={!!this.state.shapedImageUri}
                    dimmer="inverted"
                    size='mini'
                >
                    <Modal.Header>Your cup is ready</Modal.Header>
                    <Modal.Actions>
                        <Button positive
                                onClick={this.handleGoToCupClick}
                        >
                            Go to cup
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

Main.propTypes = {
    photoId: PropTypes.string.isRequired,
    shapedPhoto: PropTypes.object,
    loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        photoId: state.data.photoId,
        shapedPhoto: state.data.shapedPhoto,
        loading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    return {
        shapeActions: bindActionCreators(shapeActions, dispatch),
        makeACup: bindActionCreators(makeACup, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main);