import thunk from "redux-thunk";
import rootReducer from "../reducers";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import {applyMiddleware, compose, createStore} from "redux";

export default function configureStore(initialState) {
    if (process.env.NODE_ENV === 'production') {
        return createStore(
            rootReducer,
            initialState,
            applyMiddleware(thunk)
        );
    } else {
        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

        return createStore(
            rootReducer,
            initialState,
            composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
        );
    }
}



