import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Button} from 'semantic-ui-react'

const uploadControlId = 'uploadControlId';

class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {file: '', imagePreviewUrl: '', selected: false};
    }

    onChangeFile = () => {
        const fileButton = document.getElementById(uploadControlId);
        const file = fileButton ? fileButton.files[0] : null;

        let reader = new FileReader();

        reader.onloadend = () => {
            this.setState({
                file: file,
                selected: true,
                imagePreviewUrl: reader.result
            });

            this.props.onSelected({
                    file: file,
                    uri: reader.result
                }
            );
        };

        reader.readAsDataURL(file);
    };

    render() {
        return (
            <div className="previewComponent" style={{display: 'inline-block'}}>
                <Button
                    style={{width: '150px', margin: '5px'}}
                    as="label"
                    positive={this.state.selected}
                    htmlFor={uploadControlId}
                >
                    {this.state.selected ? 'Change' : 'Select'} Photo
                </Button>
                <input type="file"
                       accept="image/*"
                       id={uploadControlId}
                       hidden
                       onChange={this.onChangeFile}/>
            </div>
        )
    }
}

ImageUpload.propTypes = {
    onSelected: PropTypes.func.isRequired,
    fileId: PropTypes.string.isRequired
};

export default ImageUpload;
